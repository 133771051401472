// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-regulations-tsx": () => import("./../../../src/pages/regulations.tsx" /* webpackChunkName: "component---src-pages-regulations-tsx" */),
  "component---src-templates-apartment-tsx": () => import("./../../../src/templates/apartment.tsx" /* webpackChunkName: "component---src-templates-apartment-tsx" */)
}

